<template>
  <div class="tabulation">
    <el-card class="chart-card">
      <!-- 搜索框 -->
        <div class="grabble">
          <div style="width: 96%;display: flex;align-items: center;">
            <el-input
              class="hunt"
              v-model="name"
              placeholder="请输入会员姓名"
              size="large"
              @input="orangutan"
			  clearable 
            />
            <el-input
              class="hunt"
              v-model="topic"
              placeholder="请输入会员编码"
              size="large"
              @input="orangutan"
			  clearable 
            />
            <!-- 会员组织名称搜索 -->
              <el-select
                class="hunt"
                v-model="ability"
                placeholder="请选择组织名称"
                size="large"
                @click="multiple"
                clearable
                @change="numerical"
				@focus="closeIt"
				ref="selectIt"
              />
            <!-- 支付类型 -->
            <el-select v-model="achieve" @change="fulfill" class="hunt" placeholder="请选择支付类型" clearable @clear="empty" size="large">
              <el-option label="积分" value="1"  style="width:100%; text-align-last: center;" ></el-option>
              <el-option label="提货券" value="2" style="width:100%; text-align-last: center;"></el-option>
              <el-option label="现金" value="3" style="width:100%; text-align-last: center;"></el-option>
              <el-option label="现金加积分" value="4" style="width:100%; text-align-last: center;"></el-option>
              <el-option label="现金加提货券" value="5" style="width:100%; text-align-last: center;"></el-option>
            </el-select>
			<!-- 订单号 -->
			<el-input
			  class="hunt"
			  v-model="orderNumber"
			  placeholder="请输入订单号"
			  size="large"
			  @input="orangutan"
			  clearable 
			/>
        </div>
        <!-- <div>
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="large"
            @click="append"
            ><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button
          >
        </div> -->
      </div>
      <div class="grabble3">
        <!-- 是否活动商品 -->
              <el-select v-model="activity" @change="fulfill" class="hunt" placeholder="请选择是否为活动商品" clearable @clear="empty" size="large">
                <el-option label="是" value="1"  style="width:100%; text-align-last: center;" ></el-option>
                <el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
              </el-select>
              <!-- 活动编码 -->
              <el-input
                class="hunt"
                v-model="encipher"
                placeholder="请输入活动编码"
                size="large"
                @input="orangutan"
				clearable 
              />
        <!-- 时间选择 -->
              <el-date-picker
                  class="hunt"
                  v-model="value1"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  size="large"
                  @change="immediUpdate"
                />



                
            <el-button style="margin-left: 10px" type="primary" size="large" @click="search1" :loading='formative'
              ><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button
            >
      </div>
           <div class="statistics">
            统计：{{altogether}}条
          </div>
      <!-- <div class="summation">
        <div class="hint">统计 : {{altogether}}篇</div>
        <div class="hint">提示 : 动态只有发布人所管理工会会员可见，其他不可见。</div>
      </div> -->
      <el-table
        :data="filterTableData"
        style="width: 100%"
        row-key="unionId"
        @cell-mouse-enter="obtain"
        border
        :empty-text="texts"
        :header-cell-style="{ background: '#F5F7FA' }"
      >
        <!-- 会员姓名 -->
        <el-table-column
         fixed 
          label="会员姓名"
          prop="name"
          align="center"
          :show-overflow-tooltip='true'
          width="120" 
        >
        </el-table-column>
        <!-- 会员编码  -->
        <el-table-column
          label="会员编码 "
          prop="username"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
          width="120" 
        >
        </el-table-column>
        <!-- 商品名称  -->
        <el-table-column
          label="商品名称 "
          prop="goodsName"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
          width="450" 
        >
        </el-table-column>
		<!-- 订单号  -->
		<el-table-column
		  label="订单号 "
		  prop="orderNumber"
		  align="center"
		  :formatter="quantum"
		  :show-overflow-tooltip='true'
		  width="200" 
		>
		</el-table-column>
        <!-- 支付类型 -->
        <el-table-column
          label="支付类型"
          prop="payType"
          align="center"
          :formatter="formatSex"
          :show-overflow-tooltip='true'
          width="120" 
        >
        </el-table-column>
        <!-- 现金 -->
        <el-table-column
          label="现金"
          prop="money"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 积分 -->
        <el-table-column
          label="积分"
          prop="integral"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 现金券  -->
        <el-table-column
          label="提货券 "
          prop="cash"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 消费时间  -->
        <el-table-column
          label="消费时间 "
          prop="createTime"
          align="center"
          :formatter="reach"
          :show-overflow-tooltip='true'
          width="150" 
        >
        </el-table-column>
        <!-- 所属工会  -->
        <el-table-column
          label="所属工会 "
          prop="unionName"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
          width="150" 
        >
        </el-table-column>
        <!-- 所属部门  -->
        <el-table-column
          label="所属部门 "
          prop="departmentName"
          align="center"
          width="150" 
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 是否为活动商品  -->
        <el-table-column
          label="是否为活动商品 "
          prop="isActivityGoods"
          align="center"
          :formatter="HYshifou"
          :show-overflow-tooltip='true'
          width="130" 
        >
        </el-table-column>
        <!-- 活动编码   -->
        <el-table-column
          label="活动编码 "
          prop="activityCode"
          align="center"
          :formatter="encoding"
          :show-overflow-tooltip='true'
          width="120" 
        >
        </el-table-column>
        <!-- 活动描述  -->
        <el-table-column
          label="活动描述 "
          prop="activityReason"
          align="center"
          :formatter="describe"
          :show-overflow-tooltip='true'
          width="150" 
        >
        </el-table-column>
        <!-- <el-table-column label="操作" align="center" width="265">
          <template #default="scope">
            <el-button
              size="default"
              @click.stop="copyreader(scope.row.username)"
              class="bulur"
              ><el-icon class="cancel"> <Search /> </el-icon>积分明细</el-button
            >
            <el-button size="default" @click.stop="expurgate(scope.row.username)" class="red"
              ><el-icon class="cancel"> <Search /> </el-icon>现金券明细</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 表格分页 -->
      <!-- 一页时候隐藏 hide-on-single-page="true" -->
      <el-pagination
        hide-on-single-page="true"
        layout="prev, pager, next,sizes"
        :page-sizes="[6, 12, 18, 24]"
        v-model:currentPage="currentPage"
        :page-size="pagesize"
        :total="altogether"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
     <!-- 所属组织弹窗 -->
    <div>
      <s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
        <el-tree
          :data="texture"
          :props="defaultProps"
          accordion
          @node-click="dendrogram"
        />
      </s3-layer>
    </div>
  </div>
</template>


<script>
// import { useRouter  } from "vue-router";
import { ref,onMounted,onDeactivated,onActivated,  } from 'vue';
import { XFstatistics,Zzzlist,ZClist, } from '../../utils/api'
import { Search } from "@element-plus/icons-vue";
import qs from 'qs'
import { useRouter  } from "vue-router";
import moments from 'moment'
// import { ElMessage } from "element-plus";
export default {
  name: 'XFstatistics',
  setup() {
    // 搜索加载
    const formative=ref(false)
    // 判断描述
    let describe= (row, column, cellValue) => {
      // console.log(cellValue)
      if (cellValue == null||cellValue == '') {
        return "-";
      } else if (cellValue !== null||cellValue !== '') {
        return cellValue;
      }
    };
    // 判断编码
    let encoding= (row, column, cellValue) => {
      // console.log(cellValue)
      if (cellValue == null||cellValue == '') {
        return "-";
      } else if (cellValue !== null||cellValue !== '') {
        return cellValue;
      }
    };
    // 是否活动商品
    let HYshifou= (row, column, cellValue) => {
      if (cellValue == 1) {
        return "是";
      } else if (cellValue === 2) {
        return "否";
      }
    };
    // 判断类型
    let formatSex= (row, column, cellValue) => {
      if (cellValue == 1) {
        return "积分";
      } else if (cellValue === 2) {
        return "提货券";
      } else if (cellValue === 3) {
        return "现金";
      } else if (cellValue === 4) {
        return "现金加积分";
      } else if (cellValue === 5) {
        return "现金加提货券";
      }
    };
    // let asd=ref('')
    let reach=(row, colum,cellValue )=>{
      
      // console.log(moments(cellValue*1000).format("YYYY-MM-DD HH:mm"))
      // asd.value=moments(cellValue*1000).format().substring(0,16)
      if(typeof cellValue=='number'){
        return moments(cellValue*1000).format("YYYY-MM-DD HH:mm")
      }
    }
    let a =ref('')
    let b =ref('')
    let c=ref('')
    let out=ref('')

    // 时间清空监听
    const immediUpdate=(e)=>{
      if(e!==null){
        a.value=new Date(e[0]).getTime()/1000
        b.value=new Date(e[1]).getTime()/1000
        c.value=moments(b.value*1000).format().substring(0,10)
        out.value= new Date( c.value+' '+ '23:59:59').getTime()/1000
      }else if(e==null){
        a.value=''
        b.value=''
        c.value=''
        out.value=''
      }
      console.log(e)
    }
    // 时间选择
    let value1=ref('')
    // 活动编码
    let encipher=ref('')
    // 活动商品
    let activity=ref('')
    // 支付类型
    let achieve=ref('')
    // 现金券明细
    const expurgate=(res)=>{
      sessionStorage.setItem('YEticket', res)
      router.push('/YEticket')
      console.log(res)
    }

	let selectIt = ref()
	// 下拉隐藏
	let closeIt = () => {
		selectIt.value.blur();
	}
	


    // 路由跳转
    const router = useRouter()
    // 积分明细
    const copyreader=(res)=>{
      sessionStorage.setItem('YEintegral', res)
      router.push('/YEintegral')
      console.log(res)
    }
    // 会员编码
    const topic=ref('')
    // 会员姓名
    const name = ref('')
    const zong =ref(null)
	//订单号
	const orderNumber = ref('')
    // 工会清空
    const numerical=(e)=>{
      if(e==''){
        Hcode.value=''
      }
    }

    
        // 获取code 
    let Hcode=ref('')
    // 搜索组织选择值
    let ability=ref('')
    // 弹窗树形选择
    let dendrogram = (node)=>{
      ability.value=node.unionName
      Hcode.value=node.unionCode
      console.log(node)
    }
    const lang=()=>{
      ZClist().then((res)=>{
        console.log(res)
        texture.value = res.data.data.manageUnionList
      })
    }
    // 弹窗组织列表树形
    let texture =ref([])
    const defaultProps = {
      children: 'unionDtoList',
      label: 'unionName',
    }
     // 选择组织按钮
    let multiple =()=>{
      visible.value=true
    }
    // 搜索组织弹窗
    let visible=ref(false)










    // 列表内容显示
    let texts= ref('加载中')
    // 搜索框值变化清空
    let orangutan=()=>{
      // if(topic.value==''&&values.value==''){
      //   list()
      // }
    }
    let search1=()=>{
      formative.value=true
      search()
    }
    // 搜索
    let search=()=>{
      
      console.log('会员姓名',name.value)
      console.log('会员编码',topic.value)
      console.log('支付类型',achieve.value)
      console.log('工会code',Hcode.value)
      console.log('是否活动商品',activity.value)
      console.log('活动编码',encipher.value)
      console.log('开始时间',a.value)
      console.log('结束时间',b.value)
      
      console.log('jieshu',out.value)
      let data ={
        limit:branches.value,
        page:currentPage.value,
        name:name.value,
        username:topic.value,
        unionCode:Hcode.value,
		orderNumber:orderNumber.value,
        payType:achieve.value,
        startTime:a.value,
        endTime:out.value,
        isActivityGoods:activity.value,
        activityCode:encipher.value
      }
      XFstatistics(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          formative.value=false
          altogether.value=res.data.data.goodsBuyPage.totalCount
          filterTableData.value=res.data.data.goodsBuyPage.list
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    // 分页
    let currentPage=ref(1)
    let pagesize=ref(6)
    let handleSizeChange=(size)=>{
      pagesize.value=size
      branches.value=size
      if(ability.value!==''||name.value!==''||topic.value!==''|| orderNumber.value!==''|| achieve.value!==''||a.value!==''||b.value!==''||activity.value!==''||encipher.value!==''){
        search()
      }else{
        list()
      }
    }
    let handleCurrentChange=(size)=>{
      currentPage.value=size
      if(ability.value!==''||name.value!==''||topic.value!==''|| orderNumber.value!==''|| achieve.value!==''||a.value!==''||b.value!==''||activity.value!==''||encipher.value!==''){
        search()
      }else{
        list()
      }
    }
    // 总条数
    let altogether=ref(null)
    // 工会动态列表
    let branches=ref(6)
    const list =()=>{
      let data ={
        // 六条数据
        limit:branches.value,
        // 1页
        page:currentPage.value,
        name:'',
        username:'',
        unionCode:'',
        payType:'',
        startTime:'',
        endTime:'',
        isActivityGoods:'',
        activityCode:''
      }
      XFstatistics(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          altogether.value=res.data.data.goodsBuyPage.totalCount
          filterTableData.value=res.data.data.goodsBuyPage.list
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    // onDeactivated(()=>{
    //   list()
    // })
    // onActivated(()=>{
    //   console.log('以后只执行')
    // })
    onMounted(()=>{
      // console.log($vm0.$options.name)
      list()
      lang()
    })
    // 头部搜索
    let values=ref('')
    // 路由跳转
    // const router = useRouter()
    // 列表数据循环
    const filterTableData = ref([])
    return {
      // 搜索加载
      search1,
      formative,



      HYshifou,
      describe,
      encoding,
      formatSex,
      reach,
      immediUpdate,
      value1,
      encipher,
      activity,
      achieve,
      // 现金券明细
      expurgate,
      // 积分明细
      copyreader,
      topic,
      name,
      zong,
      numerical,
      ability,
      texture,
      defaultProps,
      dendrogram,
      visible,
      multiple,
      texts,
      orangutan,
      // 搜索
      search,
      // 分页
      handleSizeChange,
      handleCurrentChange,
      altogether,
      pagesize,
      currentPage,
      values,
      // 循环列表数据
      filterTableData,
	  orderNumber,//订单号
	  closeIt,
	  selectIt,
    };
  },
  components: {
    Search
  },
};
</script>
<style lang="scss" scoped>
.statistics{
  font-size: 14px;
  color: #909399;
  margin-bottom: 15px;
  margin-left: 10px;
}
::v-deep.el-table__body-wrapper{
    background-color: #409eff;
  }
  ::v-deep.el-table__body-wrapper::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
  }
// ::v-deep .el-table {
//   &__fixed {
//     pointer-events: none !important;
//     td {
//       pointer-events: none !important;
//     }
//   }
// }
::v-deep .el-table{
  overflow: auto !important;
}
::v-deep .dsa{
  margin-left: 10px !important;
}
::v-deep .asd{
  margin-right: 10px !important;
}
::v-deep .chart-card::-webkit-scrollbar{
  display:block !important;
}
.chart-card{
  height: calc(100vh - 145px);
  overflow: auto;
}
.summation{
  display: flex;
  justify-content: space-between;
}
::v-deep  .el-table__empty-block{
  border-bottom: 1px solid #EBEEF5 !important;
}
.hint{
  font-size: 14px;
  color: #909399;
  // font-weight: bold;
  margin-bottom: 15px;
}
.el-select--large{
  width: 20%;
  // margin-left: 10px;
}
::v-deep .el-table__body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-table__header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.hunt {
  width: 22%;
  margin-right: 10px;
  margin-left: 10px;
}
.grabble {
  width: 86%;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.grabble3 {
  width: 86%;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.el-table__header {
  margin-top: 0;
  margin-bottom: 0;
}
.red {
  color: red;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.red:hover {
  color: #fab6b6;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
// .el-scrollbar__thumb {
//   background-color: #ffffff;
// }
.items {
  display: flex;
}
.el-select .el-input__inner {
  width: 183px;
}
::v-deep .el-dialog--center .el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-select-dropdown__empty {
  display: none !important  ;
}
.el-select-dropdown__item {
  background-color: #ffffff;
  height: 40px !important;
}
.el-select-dropdown__item {
  overflow: visible;
}
.labor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tianjia {
  margin-left: 20px;
}
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
.cancelgu {
  margin-right: 6px;
  font-weight: bold;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  // overflow-x: auto;
}
::v-deep .el-card__body {
  padding: 0;
}
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
</style>
<style>
.el-popper {
  z-index: 10000 !important;
}
.modality {
  height: 150px !important;
}
/* .el-scrollbar__thumb {
  display: none !important;
} */
.el-select-dropdown__item.selected {
  font-weight: 500 !important;
}
</style>
